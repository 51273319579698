import { React, useEffect } from 'react'

const Home = () => {
    useEffect(() => {
        // Set background image for the home page
        document.body.style.backgroundImage = 'url("/images/prayerFlags.png")';

        // Clean up function to reset background image on unmount
        return () => {
            document.body.style.backgroundImage = 'none';
        };
    }, []);
    return (
        <div>
            <div class="text-green-500">Home</div>
        </div>
    )
}

export default Home