import { React, useEffect } from 'react'

const LibraryContent = () => {
    useEffect(() => {
        // Set background image for the content page
        document.body.style.backgroundImage = 'url("/images/lotus.png")';

        // Clean up function to reset background image on unmount
        return () => {
            document.body.style.backgroundImage = 'none';
        };
    }, []);
    return (
        <div>LibraryContent</div>
    )
}

export default LibraryContent