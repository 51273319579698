import { React, useEffect } from 'react'

const Discord = () => {
    useEffect(() => {
        // Set background image for the discord page
        document.body.style.backgroundImage = 'url("/images/discordBkg.png")';

        // Clean up function to reset background image on unmount
        return () => {
            document.body.style.backgroundImage = 'none';
        };
    }, []);
    return (
        <div>
            <p>Want more wisdom? Come and check out Discord Buddha, a friendly community welcoming all traditions of buddhism, dedicated to spreading the wisdom of the Buddha Dharma far and wide.</p>
            <h4>Link</h4>
        </div>
    )
}

export default Discord