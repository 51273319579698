import { React, useEffect } from 'react'

const PageNotFound = () => {
    useEffect(() => {
        // Set background image for the 404 page
        document.body.style.backgroundImage = 'url("/images/404Img.png")';

        // Clean up function to reset background image on unmount
        return () => {
            document.body.style.backgroundImage = 'none';
        };
    }, []);
    return (
        <div>
            <h1>404 Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
        </div>
    )
}

export default PageNotFound