import { React } from 'react'
import './App.css'
import { Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Home from './components/pages/Home';
import Authenticate from './components/pages/Authenticate';
import Library from './components/pages/Library';
import About from './components/pages/About';
import Discord from './components/pages/Discord';
import Admin from './components/pages/Admin';
import LibraryContent from './components/pages/LibraryContent';
import PageNotFound from './components/pages/PageNotFound';


const App = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Authenticate />} />
          <Route path="/library" element={<Library />} />
          <Route path="/library/:id" element={<LibraryContent />} />
          <Route path="/about" element={<About />} />
          <Route path="/discord" element={<Discord />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
